import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SearchSelect from '~/components/Form/Selects/SearchSelect';
import ArrowIcon from '~/components/Icons/ArrowIcon';
import {
  Advisor,
  AdvisorFirm,
} from '~/components/Form/InvestorGroupsSearchSelect/queries/useInvestorGroups';

import StyledButton from '~/components/StyledButton';
import useAdvisorOverrideSelector from '../hooks/useAdvisorOverrideSelector';

const ADVISOR_OVERRIDE_DEFAULTS = {
  advisor_override_id: '',
  advisor_override_firm_id: '',
};

const SignInAdvisorOverrideSelector = () => {
  const {
    selectedAdvisorToOverride,
    filteredAdvisorsByFirm,
    advisorFirmList,
    handleAdvisorSelection,
    handleAdvisorFirmSelection,
  } = useAdvisorOverrideSelector();

  const formattedAdvisors = filteredAdvisorsByFirm.map((advisor: Advisor) => ({
    value: advisor.id,
    label: advisor.full_name,
  }));

  const formattedAdvisorFirmList = advisorFirmList?.map(
    (firm: AdvisorFirm) => ({
      value: firm.id,
      label: firm.advisor_firm_name,
    }),
  );

  const { id: selectedAdvisorToOverrideId } =
    selectedAdvisorToOverride || ({} as Advisor);

  const { watch, setValue, ...form } = useForm({
    defaultValues: ADVISOR_OVERRIDE_DEFAULTS,
  });

  const advisorOverrideFirmId = watch('advisor_override_firm_id');
  const advisorOverrideId = watch('advisor_override_id');

  const cleanAdvisorOverrideId = () => setValue('advisor_override_id', '');

  useEffect(() => {
    handleAdvisorSelection(advisorOverrideId);
  }, [advisorOverrideId]);

  useEffect(() => {
    cleanAdvisorOverrideId();
    handleAdvisorFirmSelection(advisorOverrideFirmId);
  }, [advisorOverrideFirmId]);

  const { push } = useRouter();

  const handleNavigateToDashboard = () =>
    push('/qualified-purchasers/dashboard');

  const handleNavigateToDashboardWithoutOverride = () => {
    cleanAdvisorOverrideId();
    handleNavigateToDashboard();
  };

  return (
    <FormProvider {...{ watch, setValue, ...form }}>
      <Flex
        as="form"
        h="md"
        w="md"
        py="4"
        px="16"
        justify="center"
        align="center"
        direction="column">
        <Text fontSize="4xl" textAlign="center" w="sm" mb="10">
          Indicate which client view you want us to load for you.
        </Text>
        <VStack spacing={4} w="100%">
          <SearchSelect
            options={formattedAdvisorFirmList || []}
            data-testid="select_firm"
            aria-label="Select firm"
            label="Select firm"
            name="advisor_override_firm_id"
          />
          <SearchSelect
            options={formattedAdvisors || []}
            aria-label="Select client"
            label="Select client"
            name="advisor_override_id"
          />
        </VStack>
        <HStack mt="70px">
          <Text
            onClick={handleNavigateToDashboardWithoutOverride}
            cursor="pointer"
            textAlign="center"
            color="brand.navy"
            fontWeight="bold"
            mr="8">
            SKIP, SIGN IN AS MYSELF
          </Text>
          <StyledButton
            isDisabled={!selectedAdvisorToOverrideId}
            onClick={handleNavigateToDashboard}
            rightIcon={<ArrowIcon direction="right" size={3} />}
            variant="outline">
            VIEW
          </StyledButton>
        </HStack>
      </Flex>
    </FormProvider>
  );
};

export default SignInAdvisorOverrideSelector;
